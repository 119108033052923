import React from 'react';
import Layout from '@components/Layout/Layout';
import DownloadAppButton from '@src/components/DownloadAppButton/DownloadAppButton';
import Container from '@src/components/Container/Container';
import * as styles from './mobile-only.module.scss'

function MobileOnly() {
  return (
    <Layout path="/mobile-only">
      <Container centered={true}>
        <div className={styles.textContainer}>
          <p className={styles.heading}>Länken ska öppnas på din mobil</p>
          <DownloadAppButton />
        </div>
      </Container>
    </Layout>
  );
}

export default MobileOnly;
